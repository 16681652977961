<template>
  <nav
    id="navbar"
    class="navbar"
    v-bind:class="{ navbar_mobile: isAddMobileNavClass }"
  >
    <!-- <ul class="nav-links" v-if="page != 'detail'"> -->
       <ul class="nav-links">
     
      
        <li class="">
        <a href="https://mytpmschool.com/#/#our-program" target="_blank">{{$t("nav_our_program") }}</a>
      </li>
      <li class="">
        <a href="https://mytpmschool.com/#/#about" target="_blank">{{$t("nav_about_HPM") }}</a>
      </li>
      <li class="">
        <a href="https://mytpmschool.com/#/#blog" target="_blank">{{$t("nav_blog") }}</a>
      </li>
      <li class="">
        <a href="https://mytpmschool.com/#/#contact" target="_blank">{{$t("nav_contact_us") }}</a>
      </li>
      <li class="">
        <a href="https://mytpmschool.com/#/view-demos" target="_blank">{{$t("nav_view_demo") }}</a>
      </li>
    
      <li class="tpm_login">
        <a href="https://www.mytpmschool.org/" target="_blank">{{$t("nav_tpm") }}</a>
      </li>
      <li v-if=!isAddMobileNavClass>
        <a
          class="nav-link scrollto spain-img"
          href="#"
          @click.prevent="$i18n.locale = 'es'"
          v-if="$i18n.locale === 'en'"
          ><img src="@/assets/img/spain.png"
        /></a>
        <a
          class="nav-link scrollto spain-img"
          href="#"
          @click.prevent="$i18n.locale = 'en'"
          v-if="$i18n.locale === 'es'"
          ><img src="@/assets/img/united-states.png"
        /></a>
      </li>
      <li v-else>
        <a
          class="nav-link"
          href="#"
          @click.prevent="$i18n.locale = 'es'"
          v-if="$i18n.locale === 'en'"
          >{{ $t("nav_locale_es")}}</a>
        <a
          class="nav-link"
          href="#"
          @click.prevent="$i18n.locale = 'en'"
          v-if="$i18n.locale === 'es'"
          >{{ $t("nav_locale_en")}}</a>
      </li>
    </ul>
    <i class="bi bi-list mobile-nav-toggle" v-on:click="openMobileNav()"></i>
  </nav>
</template>

<script>
export default {
  name: "",
  props: ["name", "logoImg", "navLinks", "data"],
  data() {
    return {
      isAddMobileNavClass: false,
      page: null
    };
  },

  methods: {
    openMobileNav() {
      this.isAddMobileNavClass = !this.isAddMobileNavClass;
    },
    removeMobileNav() {
      this.isAddMobileNavClass = false;
    },
    scrollMeTo(refName) {
       const element = document.getElementById(refName);

      if (element) {
         element.scrollIntoView({
           block: "center",
           behavior: "smooth",
         });
      }
    },
  },
  mounted() {
    //if (window.innerWidth < 768) this.isAddMobileNavClass = true;
    //else this.isAddMobileNavClass = false;
     var currentURL = (window.location.href);
    var urlSegment = currentURL.split("/");
    this.page = urlSegment[3];
  },
};
</script>
