import {createRouter, createWebHashHistory } from 'vue-router';
import Home from '@/components/Home.vue';


const   history = createWebHashHistory ();

const router = createRouter({
    history,
    routes :[
        {
            path: '/',
            name: 'Home',
            component: Home,//shsould be imported 
           
          },
         
    ],
    scrollBehavior (to, from, savedPosition) {
      window.scrollTo(0,0);
      if (to.hash) {
        return {
          el: to.hash,
        }
      }
    }
  })
  export default router;