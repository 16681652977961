<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container" id="contact">
      <div class="row copyright-footer">
        <div class="col-xl-6">
          <p>&copy;{{new Date().getFullYear()}} Home and Media Products, LLC - {{ $t("footer_new") }}</p>
        </div>       
      </div>
    </div>
  </footer>
  <!-- End  -->
</template>