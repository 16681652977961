export default {
  "nav_our_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our program"])},
  "nav_about_HPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About TPM"])},
  "nav_blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
  "nav_testimonies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonies"])},
  "nav_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "nav_view_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Demos"])},
  "nav_locale_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "nav_locale_es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "nav_tpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® Login"])},
  "banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan, learn and grow together..."])},
  "banner_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPM® will be their classmate at home, we will help them get through their everyday school tasks and together we'll built a brilliant future for your kids."])},
  "banner_free_trial_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request demo"])},
  "banner_contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "team_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
  "member_luccas_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Dept"])},
  "member_luccas_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Luccas Medina, I am in charge of making the installations and customer service. I currently have 4 years of experience as a networking engineer. My hobbies generally consist of singing and playing basketball. In my spare time I enjoy playing video games and reading. About my literary tastes, my favorite books are: 11 Rings and Mamba Mentality."])},
  "member_luccas_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service - Installation Dept.Manager"])},
  "member_luccas_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_luccas_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8635 Florence Ave. S-205 Downey, CA 90240"])},
  "member_luccas_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_luccas_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_luccas_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_edgar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edgar Chavez"])},
  "member_edgar_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am Edgar, I have 22 years training people and 7 years in educational programs, my favorite books are: El camino del Lobo, Piensa y Hagase Rico, I like to go to the movies, read, study, coaching, in my rest time I like to live with family and go shopping."])},
  "member_edgar_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chavez Services"])},
  "member_edgar_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono:"])},
  "member_edgar_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_edgar_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo eléctronico "])},
  "member_edgar_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web "])},
  "member_edgar_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_eduardo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eduardo Porras"])},
  "member_eduardo_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_eduardo_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studia"])},
  "member_eduardo_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(714)574 5340"])},
  "member_eduardo_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_eduardo_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_eduardo_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "member_eduardo_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_eduardo_text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(562)399 8088"])},
  "member_elizabeth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elizabeth Cruz"])},
  "member_elizabeth_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_elizabeth_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E Cruz Services"])},
  "member_elizabeth_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_elizabeth_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_elizabeth_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_elizabeth_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "member_elizabeth_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_erick_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erick Lopez"])},
  "member_erick_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Erick Lopez Rodriguez, I am an education advisor. I currently have 3 years as an SDA chruch teacher/counselor, 8 years as an education vendor. My hobbies generally consist of singing, playing guitar. Regarding my literary tastes, my favorite books are: The Bible, The Desired of All Centuries, The Conflict of All Centuries and 3 Little Women."])},
  "member_erick_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erick Education"])},
  "member_erick_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_erick_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_erick_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_erick_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "member_erick_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_gricelda_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gricelda Lopez"])},
  "member_gricelda_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Gricelda Lopez, I am an executive representative. I have 20 years of experience as a supervisor in the educational area, 5 years ago visiting families to guide them on how to help their children to one day make it to university. My hobbies generally consist of riding a bike on the beach, enjoying my family, cook what they like, walk my dogs. About my literary tastes, my favorite books are: Your erroneous zones, Think, the path of the wolf."])},
  "member_gricelda_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gricelda E Services"])},
  "member_gricelda_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_gricelda_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Downey, CA 90240"])},
  "member_gricelda_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_gricelda_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_gricelda_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_griselda_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griselda Guerrero"])},
  "member_griselda_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_griselda_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guerrero Services"])},
  "member_griselda_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_griselda_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Downey, CA 90240"])},
  "member_griselda_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_griselda_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_griselda_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_jennifer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jennifer Gutierrez"])},
  "member_jennifer_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "member_jennifer_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J Gutierrez Services"])},
  "member_jennifer_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_jennifer_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Downey, CA 90240"])},
  "member_jennifer_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_jennifer_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_jennifer_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "member_patricia_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patricia Linares"])},
  "member_patricia_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name is Patricia Linares, I am an educational representative. I have 15 years of experience supervising and coaching groups and 6 years as an educational consultant. My hobbies generally consist of gardening and shopping. In my free time I enjoy a good dinner and being able to walk along the coast. About my literary tastes, my favorite books are: The power of the metamorphosis by Salvador Carrion, novels by Isabel Allende among others."])},
  "member_patricia_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" P. Lindsay"])},
  "member_patricia_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
  "member_patricia_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downey, CA 90240"])},
  "member_patricia_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_patricia_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
  "member_patricia_text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "footer_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" This authorized TPM® distributor is an independent contractor"])},
  "request_contact_tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "request_contact_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave us your data to be able to show you a demo of our product."])},
  "request_contact_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "request_thks_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A representative will contact you to attend your request!"])},
  "request_thks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])},
  "request_tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
  "request_tryAgain_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not register your data, please try again or contact us via"])},
  "btn_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
}